<template>
    <div class="InventoryRecordsDetails">
        <el-page-header @back="$router.go(-1)" content="记录明细"></el-page-header>
        <div class="f-w-b">
            <NavigationBar></NavigationBar>
            <div class="main">
                <div class="banner">
                    {{ enterpriseStateInfo.enterpriseName }}
                </div>
                <div class="boxShadow infoTable">
                    <div>
                        <div class="title">产品信息</div>
                        <div v-for="(item, index) of productinfo" :key="index">
                            <div v-if="item.value || item.value1 || item.value2" class="f-c-b titleItem">
                                <span class="titval">{{ item.title }}</span>
                                <span v-if="item.value" class="value">{{ item.value }}</span>

                            </div>
                        </div>
                        <div class="title mt-30">变更信息</div>
                        <div>
                            <div v-for="(item, index) of changeinfo" :key="index">
                                <div v-if="item.value" class="f-c-b titleItem">
                                    <span class="titval">{{ item.title }}</span>
                                    <div class="f-c w--50" v-if="item.title == '销售单编号'">
                                        <span class="value">{{ item.value }}</span>
                                        <div class="w--50" @click="HandleBtn('salesOrder', relationId)"
                                            style="height: 40px;width: 80px; background: linear-gradient(106deg, #026EF4 0%, #38A8F8 100%);border-radius: 10px;color: #FFF;line-height: 40px;text-align: center;cursor: pointer;">
                                            查看</div>
                                    </div>
                                    <div class="f-c w--50" v-else-if="item.title == '退货单编号'">
                                        <span class="value">{{ item.value }}</span>
                                        <div class="w--50" @click="HandleBtn('returnGoods', relationId)"
                                            style="height: 40px;width: 80px; background: linear-gradient(106deg, #026EF4 0%, #38A8F8 100%);border-radius: 10px;color: #FFF;line-height: 40px;text-align: center;cursor: pointer;">
                                            查看</div>
                                    </div>
                                    <div class="f-c w--50"
                                        v-else-if="item.title == '变更类型' && (stockCtype == 'CINP' || stockCtype == 'COUT')">
                                        <span class="value">{{ item.value }}</span>
                                        <div class="w--50" @click="checkSourceRecord"
                                            style="height: 40px;width: 80px; background: linear-gradient(106deg, #026EF4 0%, #38A8F8 100%);border-radius: 10px;color: #FFF;line-height: 40px;text-align: center;cursor: pointer;">
                                            查看源记录</div>
                                        <!-- <el-button type="success" @click="checkSourceRecord">查看源记录</el-button> -->
                                    </div>
                                    <div class="f-c w--50"
                                        v-else-if="item.title == '变更类型' && (stockCtype != 'CINP' && stockCtype != 'COUT' && cancelFlag == 'Y')">
                                        <span class="">{{ item.value }}</span>
                                        <span class="col-red">(已作废)</span>
                                    </div>
                                    <span class="value" v-else>{{ item.value }}</span>
                                </div>
                            </div>
                        </div>

                        <div v-if="customerinfo && customerinfo.length != 0" class="title mt-30">客户信息</div>
                        <div>
                            <div v-for="(item, index) of customerinfo" :key="index">
                                <div v-if="item.value" class="f-c-b titleItem">
                                    <span class="titval">{{ item.title }}</span>
                                    <span class="value">{{ item.value }}</span>
                                </div>
                            </div>
                        </div>

                        <div v-if="supplierinfo && supplierinfo.length != 0" class="title mt-30">供应商信息</div>
                        <div>
                            <div v-for="(item, index) of supplierinfo" :key="index">
                                <div v-if="item.value" class="f-c-b titleItem">
                                    <span class="titval">{{ item.title }}</span>
                                    <span class="value">{{ item.value }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="title mt-30">订单备注</div>
                        <div class="modifyNote">
                            {{ stockChangeNote }}
                        </div>
                    </div>
                </div>
                <div class="f-c-c w--100 mt-30" v-show="enterpriseStateInfo.userPermission.i_m != 'R'">
                    <div v-if="isShowCancel && cancelFlag != 'Y'" @click="cancleDetail" class="btn">作废</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { UrlDecodeId } from '@/mixins/UrlDecodeMixin'
import { InventoryStockCtypeClass } from '@/utils/InventoryStockCtypeClass'
export default {
    name: 'InventoryRecordsDetails',
    components: {
        NavigationBar: () => import(/* webpackChunkName: "UploadVideo" */ '../components/NavigationBar.vue')
    },
    mixins: [UrlDecodeId],
    data() {
        return {
            detailsInfo: [],
            productinfo: [],
            changeinfo: [],
            customerinfo: [],
            supplierinfo: [],
            stockChangeNote: '',//备注信息
            stockRecordId: '',
            stockCtype: '',
            cancelFlag: '',//作废标记 stockCtype不是是C开头的并且cancelFlag为Y则打上作废标记
            relationId: '',//查看源关联id
            ChangeNoType: '',//区分变更信息中编号类型  1-销售单编号（跳转） 2-退回单编号（不跳转） 3-退货单编号（跳转）
            enterpriseStateInfo: this.$getStorage('enterpriseStateInfo'),
            isShowCancel: false,//是否显示作废按钮
        }
    },
    created() {
        this.productStockListDetails(this.queryId)
    },
    methods: {
        // 详情
        productStockListDetails(stockRecordId) {
            let that = this;
            that.$http.productStockListDetails({
                changeUserId: parseInt(that.$store.state.userId),
                token: that.$getStorage('token'),
                stockRecordId
            }).then(res => {
                let { productName, customerUseName, productNumber, stockCtype, changeUsername, createDate, stockChangeNumber, cancelFlag, relationId,
                    sdName, sdPhone, sdContact, sdCity, sdAddress, scAfterNumber, scBeforeNumber, stockChangeNote, stockRecordId, relationStr, customerUseNumber } = res.data,
                    { attributeValue2 } = res.data.productAttribute,
                    address = sdCity + sdAddress,
                    stockCtypeText = InventoryStockCtypeClass.getStockCtype(stockCtype),
                    recordType = InventoryStockCtypeClass.getRecordType(stockCtype), //区分展示客户信息/供应商信息 
                    ChangeNoType = InventoryStockCtypeClass.getChangeNoType(stockCtype),//区分变更信息中编号类型  1-销售单编号（跳转） 2-退回单编号（不跳转） 3-退货单编号（跳转）
                    // 是否显示作废按钮
                    stockCtypeClass = {
                        INP0: () => { return true },
                        INP1: () => { return true },
                        OUT0: () => { return true },
                        OUT1: () => { return true },
                        OUT2: () => { return true },
                    };
                that.isShowCancel = stockCtypeClass[stockCtype] ? stockCtypeClass[stockCtype]() : false;
                that.stockChangeNote = stockChangeNote ? stockChangeNote : '无';
                that.stockRecordId = stockRecordId;
                that.stockCtype = stockCtype;
                that.ChangeNoType = ChangeNoType;
                that.cancelFlag = cancelFlag;//作废标记
                that.relationId = relationId;//关联id

                let customerUseName1 = customerUseName ? '(' + customerUseName + ')' : '',
                    productNumber1 = productNumber ? productNumber : '未设置',
                    customerUseNumber1 = customerUseNumber ? '(' + customerUseNumber + ')' : '';


                let productinfo = [{
                    title: '产品名称',
                    value: productName + customerUseName1
                }, {
                    title: '产品编号',
                    value: productNumber1 + customerUseNumber1
                }, {
                    title: '材质类型',
                    value: attributeValue2 ? attributeValue2 : '未设置'
                }],
                    changeinfo = [{
                        title: '变更类型',
                        value: stockCtypeText
                    }, {
                        title: ChangeNoType == 1 ? '销售单编号' : (ChangeNoType == 2 ? '退回单编号' : (ChangeNoType == 3 ? '退货单编号' : '')),
                        value: relationStr
                    }, {
                        title: '变更人',
                        value: changeUsername
                    }, {
                        title: '变更时间',
                        value: createDate
                    }, {
                        title: '变更数量',
                        value: stockChangeNumber.toFixed(2) + ' ( ' + scBeforeNumber.toFixed(2) + ' --> ' + scAfterNumber.toFixed(2) + ' )'
                    }],
                    supplierinfo = [],
                    customerinfo = []

                if (sdName) {
                    supplierinfo = [
                        {
                            title: '供应商',
                            value: sdName ? sdName : '无'
                        }, {
                            title: '联系电话',
                            value: sdPhone ? sdPhone : '无'
                        }, {
                            title: '联系人',
                            value: sdContact ? sdContact : '无'
                        }, {
                            title: '地址',
                            value: address ? address : '无'
                        }
                    ];
                    customerinfo = [
                        {
                            title: '客户名称',
                            value: sdName ? sdName : '无'
                        }, {
                            title: '联系电话',
                            value: sdPhone ? sdPhone : '无'
                        }, {
                            title: '联系人',
                            value: sdContact ? sdContact : '无'
                        }, {
                            title: '地址',
                            value: address ? address : '无'
                        }
                    ]
                }else{
                    supplierinfo = []
                }
                if (sdName) {
                    customerinfo = [
                        {
                            title: '客户名称',
                            value: sdName ? sdName : '无'
                        }, {
                            title: '联系电话',
                            value: sdPhone ? sdPhone : '无'
                        }, {
                            title: '联系人',
                            value: sdContact ? sdContact : '无'
                        }, {
                            title: '地址',
                            value: address ? address : '无'
                        }
                    ]
                }else{
                    customerinfo = []
                }
                if (recordType == 1) {
                    // list.push(list1)
                    // that.detailsInfo = list
                    that.changeinfo = changeinfo;
                    that.productinfo = productinfo;
                    that.supplierinfo = supplierinfo;
                } else if (recordType == 2) {
                    // list.push(list2)
                    // that.detailsInfo = list;
                    that.changeinfo = changeinfo;
                    that.productinfo = productinfo;
                    that.customerinfo = customerinfo;
                } else {
                    // that.detailsInfo = list
                    that.changeinfo = changeinfo;
                    that.productinfo = productinfo;
                }
            })
        },
        // 点击查看销售单、退货单
        HandleBtn(type, id) {
            let enterpriseStateInfo = this.$getStorage('enterpriseStateInfo');
            // 销售单
            if (type == 'salesOrder') {
                if (enterpriseStateInfo.userPermission.o_m == 'D') {
                    return this.$common.message('您没有权限查看，请联系企业管理员处理！', 'warning', 2000)
                }
                this.$router.push({
                    name: 'OrderDetails', query: {
                        key: this.$UrlEncode.encode(JSON.stringify({
                            id,
                            orderStatus: 'All'
                        }))
                    }
                })
            }
            // 退货单
            if (type == 'returnGoods') {
                if (enterpriseStateInfo.userPermission.o_m == 'D') {
                    return this.$common.message('您没有权限查看，请联系企业管理员处理！', 'warning', 2000)
                }
                this.$router.push({
                    name: 'ReturnOrderDetails', query: {
                        key: this.$UrlEncode.encode(JSON.stringify({
                            id,
                        }))
                    }
                })
            }
        },


        // 作废
        cancleDetail() {
            let that = this;
            that.$confirm('作废该条记录？', '提示').then(() => {
                that.$http.updateProductStockBecomeInvalid({
                    changeUserId: parseInt(that.$store.state.userId),
                    token: that.$getStorage('token'),
                    stockRecordId: parseInt(that.stockRecordId)
                }).then(res => {
                    if (res.code == 200) {
                        that.$common.message(res.msg, 'success')
                        that.$common.DelayedBack();
                    } else {
                        that.$common.message(res.msg, 'error')
                    }
                })
            }).catch(() => {

            })
        },
        // 查看源记录
        checkSourceRecord() {
            this.productStockListDetails(this.relationId)
        },
        check() {
            this.$router.push({
                name: 'OrderDetails'
            })
        }
    },
}

</script>

<style lang='less' scoped>
@import "@/assets/css/TableList.less";

.InventoryRecordsDetails {
    .main {
        width: 980px;

        .banner {
            background: url('../../../assets/supply/supplyBanner.png');
            height: 100px;
            color: #FFFFFF;
            font-size: 30px;
            line-height: 100px;
            text-align: center;
            margin-bottom: 40px;
        }

        .infoTable {
            width: 899px;
            padding: 40px;
            border-radius: 10px;

            .title {
                margin-bottom: 22px;
                border-left: 4px solid #0363FA;
                padding-left: 10px;
                font-size: 24px;
                font-weight: 500;
                color: #333333;
            }

            .titleItem {
                padding: 10px 30px;
                width: 840px;
                height: 50px;
                border: 1px solid #f2f2f2;
                margin-bottom: 5px;

                .titval {
                    font-size: 16px;
                    color: #999;
                    font-weight: 400;
                    width: 50%;
                }

                .value {
                    line-height: 20px;
                    font-size: 16px;
                    color: #333;
                    font-weight: 400;
                    width: 50%;
                }
            }

            .talTit {
                border-top: 1px solid #EDECEC;
                border-left: 1px solid #EDECEC;
                background: #F8F8F8;
                // border-radius: 5px 5px 0px 0;

                span {
                    width: calc(100% / 6 - 6px);
                    text-align: center;
                    line-height: 60px;
                    color: #333;
                    font-weight: 500;
                    font-size: 18px;
                    border-right: 1px solid #EDECEC;
                }
            }

            .talVal {
                // min-heightht: 60px;
                background: #fff;
                border-left: 1px solid #EDEDED;
                // border-right: 1px solid #EDEDED;
                border-bottom: 1px solid #EDEDED;

                span {
                    width: calc(100% / 6 - 6px);
                    text-align: center;
                    line-height: 60px;
                    color: #333;
                    font-weight: 400;
                    font-size: 16px;
                    border-right: 1px solid #EDEDED;
                }

                div {
                    width: calc(100% / 6 - 6px);
                    text-align: center;
                    color: #333;
                    font-weight: 400;
                    font-size: 16px;
                    border-right: 1px solid #EDEDED;
                    word-break: break-all;
                    word-wrap: break-word;
                    line-height: 25px;
                }
            }

            .modifyNote {
                padding: 30px;
                // height: 230px;
                background: #F3F8FE;
                border-radius: 5px;
                color: #666666;
                white-space: pre-line;
                font-size: 16px;
                font-weight: 400;
                line-height: 28px;
            }
        }

        .btn {
            width: 160px;
            height: 50px;
            background: linear-gradient(106deg, #026EF4 0%, #38A8F8 100%);
            border-radius: 10px;
            color: #FFF;
            font-size: 16px;
            line-height: 50px;
            text-align: center;
            cursor: pointer;
        }
    }

    .btns {
        margin: 50px auto;

        .el-button.is-round {
            width: 50%;
        }
    }
}
</style>
