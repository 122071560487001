
// 库存类型
const stockCtype = {
    INP0: () => { return '入库（采购）' },
    INP1: () => { return '入库（其他）' },
    CINP: () => { return '库存扣除（取消入库）' },
    OUT0: () => { return '产品报废' },
    OUT1: () => { return '其他出库' },
    OUT2: () => { return '退回供应商' },
    COUT: () => { return '库存恢复' },
    SAOT0: () => { return '销售出库' },
    SAOT1: () => { return '销售出库(订单修改)' },
    CSAOT0: () => { return '库存恢复（订单取消）' },
    CSAOT1: () => { return '库存恢复（订单修改）' },
    SAIN0: () => { return '库存恢复（订单退货）' }
}

//区分变更信息中编号类型  1-销售单编号（跳转） 2-退回单编号（不跳转） 3-退货单编号（跳转）
const ChangeNoType = {
    // 销售单编号（跳转）
    SAOT0: () => { return 1 },
    SAOT1: () => { return 1 },
    CSAOT0: () => { return 1 },
    CSAOT1: () => { return 1 },
    //退回单编号（不跳转）
    OUT2: () => { return 2 },
    // 退货单编号（跳转）
    SAIN0: () => { return 3 }
}

//区分展示客户信息/供应商信息  1-供应商信息 2-客户信息
const recordType = {
    //  1-展示供应商信息
    INP0: () => { return 1 },
    //INP1: () => { return 1 },
    CINP: () => { return 1 },
    //OUT0: () => { return 1 },
    //OUT1: () => { return 1 },
    OUT2: () => { return 1 },
    COUT: () => { return 1 },
    // 2-展示客户信息
    SAOT0: () => { return 2 },
    SAOT1: () => { return 2 },
    CSAOT0: () => { return 2 },
    CSAOT1: () => { return 2 },
    SAIN0: () => { return 2 }
}

const InventoryStockCtypeClass = {
    // 根据类型获取库存提示
    getStockCtype(type) {
        return stockCtype[type] ? stockCtype[type]() : ''
    },
    // 判断当前类型是什么单
    getChangeNoType(type) {
        return ChangeNoType[type] ? ChangeNoType[type]() : ''
    },
    // 判断当前类型是什么单
    getRecordType(type) {
        return recordType[type] ? recordType[type]() : ''
    }
}

export {
    InventoryStockCtypeClass
}